<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">人员管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">职工列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="职工姓名" class="searchboxItem ci-full">
              <span class="itemLabel">职工姓名:</span>
              <el-input
                v-model="searchName"
                type="text"
                clearable
                size="small"
                placeholder="请输入职工姓名"
              />
            </div>
            <div title="身份证号" class="searchboxItem ci-full">
              <span class="itemLabel">身份证号:</span>
              <el-input
                v-model="searchIdcard"
                type="text"
                clearable
                size="small"
                placeholder="请输入身份证号"
              />
            </div>
            <div title="手机号" class="searchboxItem ci-full">
              <span class="itemLabel">手机号:</span>
              <el-input
                v-model="searchPhone"
                type="text"
                clearable
                size="small"
                placeholder="请输入手机号"
              />
            </div>
            <el-button class="bgc-bv" round @click="getData()">查询</el-button>
          </div>
          <div class="df ci-full">
            <el-button class="bgc-bv" round @click="add()">新增职工</el-button>
            <el-button class="bgc-bv" round @click="Exportcomp">导入职工</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              stripe
              :header-cell-style="tableHeader"
            >
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" />
              <el-table-column label="姓名" align="left" show-overflow-tooltip prop="userName" />
              <el-table-column label="身份证号" align="left" show-overflow-tooltip prop="idcard" />
              <el-table-column label="手机号" align="left" show-overflow-tooltip prop="mobile" />
              <el-table-column label="操作" align="center" width="400px">
                <div slot-scope="scope" class="flexcc">
                  <div style="margin-right:10px">
                    <el-button
                      style="padding:0px 15px"
                      type="text"
                      size="mini"
                      @click="doRoute(scope.row.userId)"
                    >学习记录</el-button>
                  </div>
                  <div>
                    <el-button
                      style="padding:0px 15px"
                      type="text"
                      size="mini"
                      @click="handleEdit(scope.row.userId)"
                    >编辑</el-button>
                    <el-button
                      style="padding:0px 15px"
                      type="text"
                      size="mini"
                      @click="handleDel(scope.row.userId)"
                    >删除</el-button>
                  </div>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <el-dialog
      title="导入职工"
      :visible.sync="centerDialogVisible"
      width="50%"
      center
      :before-close="doCloseload"
      class="exportdialog"
    >
      <div class="flexdcc">
        <div class="export-box">
          <h1>
            1
            <span>下载并填写导入模板</span>
          </h1>
          <div class="df">
            <span
              @click="downloadExcel"
              style="margin-top:20px;margin-right:20px;color:#409eff;cursor: pointer;"
            >职工导入基础模板</span>
          </div>
        </div>
        <div class="export-box">
          <h1>
            2
            <span>导入编写好的Excel文档,并且选择职工学习的课程</span>
          </h1>
          <div class="flexdc">
            <div class="df" style="margin:20px 0 10px">
              <el-upload
                class="upload-demo upload-workers"
                :action="actionUrl"
                :on-error="handleError"
                :on-success="handleSuccess"
                :on-change="uploadChange"
                :show-file-list="false"
                :auto-upload="false"
              >
                <el-button class="bgc-bv" style="font-size:12px;width: 80%;">浏览</el-button>
              </el-upload>
              <p v-if="fileName" style="margin-top:10px;margin-left:10px">
                当前选择文件：
                <span style="color:#f46173">{{fileName}}</span>
              </p>
              <p v-else style="margin-top:10px;margin-left:10px">未选择文件</p>
            </div>
            <div>
              <el-button
                class="bgc-bv"
                size="mini"
                style="margin-top:10px;height: 35px;"
                @click="doExport"
              >开始导入</el-button>
            </div>
          </div>
        </div>
        <div class="export-box">
          <h1>
            3
            <span>导入结果</span>
          </h1>
          <div class="df studentNum">
            <span>职工总数量:{{totalNum}}人;</span>
            <span>成功:{{correctNum}}人;</span>
            <span>失败:{{errorNum}}人;</span>
          </div>
          <div>
            <el-button
              class="bgc-bv"
              style="margin-top:15px;height: 35px;"
              size="mini"
              :disabled="errorNum == '0'"
              @click="doExportError"
            >导出错误数据</el-button>
          </div>
          <div
            style="margin-top:15px;color:#DD1D35;"
            v-if="errorNum != '0'"
          >有导入失败职工，请导出错误数据，将导入失败职工调整后重新上传</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import {resetKeepAlive} from "@/utils/common"
export default {
  name: "workersList",
  components: {
    Empty,
    PageNum
  },
  mixins: [List],
  data() {
    return {
      fileName: "",
      fileKey: "",
      excelUrl: "",
      searchName: "",
      searchIdcard: "",
      searchPhone: "",
      centerDialogVisible: false,
      totalNum: "0",
      errorNum: "0",
      correctNum: "0",
      batchId: "",
      newvalues: [] // 存储value的数组
    };
  },
  computed: {},
  mounted() {
    // this.getTableHeight();
  },
  methods: {
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        userName: this.searchName || null,
        idcard: this.searchIdcard || null,
        mobile: this.searchPhone || null
      };

      this.doFetch({
        url: "/biz/user/queryUserList",
        params,
        pageNum
      });
    },
    // 修改
    handleEdit(id) {
      this.$router.push({
        path: "/web/addworkers",
        query: {
          id,
          stu: "edit"
        }
      });
    },
    // 新增
    add() {
      this.$router.push({
        path: "/web/addworkers",
        query: {
          stu: "add"
        }
      });
    },
    doRoute(id) {
      this.$router.push({
        path: "/web/studyNotes",
        query: {
          id
        }
      });
    },
    handleDel(id) {
      this.$confirm("你确定要删除该员工吗?", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$post("/biz/user/deleteUser", { userId: id }).then(res => {
            if (res.status == 0) {
              this.$message({
                type: "success",
                message: "删除成功!"
              });
              this.getData(-1);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    //导入 职工
    Exportcomp() {
      this.centerDialogVisible = true;
      this.fileKey = "";
      this.fileName = "";
      // this.getData();
      (this.totalNum = "0"),
        (this.errorNum = "0"),
        (this.correctNum = "0"),
        (this.batchId = "");
    },
    downloadExcel() {
      /**
       * 下载模板
       * @param param 参数
       */
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = "/static/student.xls";
      link.setAttribute("download", "student.xls");
      document.body.appendChild(link);
      link.click();
    },
    downloadExcel1() {
      /**
       * 下载模板
       * @param param 参数
       */
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = "/static/student2.xls";
      link.setAttribute("download", "student2.xls");
      document.body.appendChild(link);
      link.click();
    },
    // 导入职工
    uploadChange(file) {
      console.log(file);
      this.fileName = file.name;
      let size = file.size / 1024 / 1024;
      // let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "XLSX";
      const isxlsx = extension === "xlsx";
      const isXLS = extension === "XLS";
      const isxls = extension === "xls";
      if (!isXLSX && !isxlsx && !isXLS && !isxls) {
        this.$message.error("只能上传后缀是.xlsx或者.xls的文件");
        return;
      }
      if (size > 1) {
        this.$message.error("文件大小不能超过1M");
        return;
      }
      let formData = new FormData();
      formData.append("folder ", "USER");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);
      this.$Postformat("/sys/upload", formData)
        .then(result => {
          this.fileKey = result.data.fileKey;
          // this.fileName = result.data.fileKey;
        })
        .catch(err => {
          return;
        });
    },
    //开始导入
    doExport() {
      if (this.fileKey == "") {
        this.$message({
          message: "请选择文件",
          type: "warning"
        });
      } else {
        this.$confirm("是否将此表中职工绑定此企业?", "提示", {
          confirmButtonText: "确定",
          confirmButtonClass: "confirmButtonClass",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            if (this.fileKey) {
              const parmar = {
                // projectId: this.projectId,
                excelUrl: this.fileKey
              };
              // if (this.Form.portCoursestudy) {
              //   parmar.courseList = this.newvalues;
              // }
              this.$post("/biz/user/importCompanyUser", parmar, 5000)
                .then(res => {
                  if (res.status == 0) {
                    this.totalNum = res.data.totalNum;
                    this.errorNum = res.data.errorNum;
                    this.correctNum = res.data.correctNum;
                    this.batchId = res.data.batchId;
                    this.$message({
                      type: "success",
                      message: "导入成功"
                    });
                    this.getData();
                  }
                })
                .catch(() => {
                  return;
                });
            } else {
              this.$message({
                message: "请选择文件",
                type: "warning"
              });
            }
          })
          .catch(() => {
            return;
          });
      }
    },
    //导出错误数据
    doExportError() {
      if (this.batchId == "" || this.fileKey == "") {
        this.$message({
          message: "还没有导入数据，请先导入文档",
          type: "warning"
        });
      } else {
        this.$post(
          "/biz/user/exportProjectUser",
          { batchId: this.batchId },
          5000
        ).then(res => {
          if (res.status == 0) {
            window.open(res.data);
          }
        });
      }
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    }
  },
  beforeRouteLeave:resetKeepAlive
};
</script>
<style lang="less">
.addlist {
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.upload-workers {
  height: 40px;
  .el-upload {
    height: 40px !important;
    border: none !important;
  }
}
.exportdialog {
  .upload-workers {
    height: 30px;
    .el-upload {
      height: 30px !important;
      width: 97px;
      border: none !important;
      .el-button {
        padding: 5px 0;
      }
    }
  }
}
</style>
<style lang="less" scoped>
.addlist {
  h3 {
    padding: 10px 0;
  }
}
.zsBtn {
  margin-left: 20px;
  button {
    height: 40px;
  }
}
.certificate-box {
  > div:last-child {
    border: 0;
  }
}
.card-border {
  padding: 10px;
  border-bottom: 1px dashed #eee;
}
.exportdialog {
  .upload-workers {
    height: 30px;
    .el-upload {
      height: 30px !important;
      width: 97px;
      border: none !important;
      .el-button {
        padding: 5px 0;
      }
    }
  }
}
.exportdialog {
  .el-dialog__title {
    font-size: 16px;
  }
  .export-box {
    width: 100%;
    margin-bottom: 20px;
    h1 {
      padding: 0 5px 10px;
      display: flex;
      align-items: flex-end;
      font-size: 40px;
      border-bottom: 1px dashed #ccc;
      span {
        margin-left: 20px;
        font-size: 14px;
      }
    }
    > div {
      padding-left: 30px;
    }
    .el-button.is-disabled,
    .el-button.is-disabled:focus,
    .el-button.is-disabled:hover {
      color: #fff;
      background: #c3c3c3;
      border: none;
    }
  }
}
.docsDialog {
  .el-form-item {
    margin-bottom: 0.5rem;
  }
}
.studentNum {
  margin: 20px 0 10px;
  span {
    margin: 0 10px;
  }
}
</style>

